import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { PARAMETER_MAPPING } from 'constants/filters'

const Select = ({choices, handleChangeFilters, item, isDisabled, newValue, placeholder}) => {
  const { t } = useTranslation('global')
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(newValue || '')
  const [search, setSearch] = useState('')
  const [filteredChoices, setFilteredChoices] = useState(choices || [])
  const [isMounted, setIsMounted] = useState(false)
  const selectRef = useRef(null)
  const inputRef = useRef(null)

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    setValue(newValue || '')
  }, [newValue])

  useEffect(() => {
    const controller = new AbortController()
  
    const fetchFilteredChoices = async () => {
      try {
        setFilteredChoices([])

        if (['department_headcount_name'].includes(item)) {
          const response = await apiClient.get(
            buildApiUrl(`/parameters`, { type: PARAMETER_MAPPING[item], keywords: search.toLowerCase() }),
            { signal: controller.signal }
          )
          setFilteredChoices(response.data)
        } else {
          const filtered = !search
            ? choices
            : choices
                ? choices.filter((choice) =>
                    t(choice.text).toLowerCase().includes(search.toLowerCase()) ||
                    choice.text.toLowerCase().includes(search.toLowerCase())
                  )
                : []
          setFilteredChoices(filtered)
        }
      } catch (error) {
        return
      }
    }
  
    if (isMounted) {
      fetchFilteredChoices()
    } else {
      setIsMounted(true)
    }
  
    return () => {
      controller.abort()
    }
  }, [search, choices])

  const toggleList = () => {
    !isDisabled && setIsOpen(!isOpen)
  }

  const handleChangeValue = (choice) => {
    setValue(choice)
    setIsOpen(false)
    handleChangeFilters(item, choice)
  }

  const handleRemoveValue = () => {
    setValue('')
    handleChangeFilters(item, null)
  }

  const handleChangeSearch = (search) => {
    setSearch(search.toLowerCase())
  }

  return (
    <div className={isDisabled ? 'select disabled' : 'select'} ref={selectRef}>
      {value ?
        <div className='select__value'>
          <span>{t(value.text)}</span>
          {!isDisabled && <FontAwesomeIcon className='select__value__trash' icon={faTrash} onClick={handleRemoveValue} />}
        </div>
        :
        !isOpen &&
          <div className='select__button' onClick={toggleList}>
            <span className='select__button__placeholder'>{placeholder ? t(placeholder) : t('select')}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
      }
      {isOpen &&
      <>
        <input
          className='select__button'
          onChange={(e) => handleChangeSearch(e.target.value)}
          ref={inputRef}
          value={search}
        />
        <ul className='select__list'>
          {filteredChoices
            .map(choice => <li key={item + choice.value} onClick={() => handleChangeValue(choice)}>{t(choice.text)}</li>)}
        </ul>
      </>
      } 
    </div>
  )
}

export default Select
