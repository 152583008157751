import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleList } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'
import PeopleTable from 'features/tables/PeopleTable'
import CompaniesTable from 'features/tables/CompaniesTable'
import ListSelector from 'components/ListSelector'
import ListCreator from 'components/ListCreator'

const Lists = () => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const [lists, setLists] = useState([])
  const [listModel, setListModel] = useState({name: t('contacts_lists'), id: 0})
  const [activeList, setActiveList] = useState(null)
  const [contacts, setContacts] = useState([])
  const [companies, setCompanies] = useState([])
  const [isCreatingNewList , setIsCreatingNewList] = useState(false)

  const handleChangeIsCreatingNewList = (boolean) => {
    setIsCreatingNewList(boolean)
  }

  const handleChangeListModel = (model) => {
    setListModel(model)
    setActiveList(null)
  }

  const handleChangeActiveList = (list) => {
    setActiveList(list)
  }

  const handleFetchLists = () => {
    apiClient.get(buildApiUrl(`/lists`))
    .then((response) => {
      setLists(response.data)
    })
  }

  const handleCreateList = (listParams) => {
    apiClient.post(buildApiUrl(`/lists`), listParams)
      .then((response) => {
        setLists((prevLists) => [...prevLists, response.data])
        showNotification(t('list_has_been_created'), 'success')
        setIsCreatingNewList(false)
        setActiveList(response.data)
        response.data.model === 'contact' && setListModel({name: t('contacts_lists'), id: 0})
        response.data.model === 'company' && setListModel({name: t('companies_lists'), id: 1})
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  const handleDetroyList = (deletedList) => {
    apiClient.delete(buildApiUrl(`/lists/${deletedList.id}`))
      .then(() => {
        setLists((prevLists) => prevLists.filter((list) => list.id !== deletedList.id))
        setContacts([])
        setCompanies([])
        showNotification(t('list_has_been_deleted'), 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  const handleRemoveFromList = (selectedIds) => {
    const ids =
      activeList.model === 'contact'
      ? contacts.filter(c => !selectedIds.includes(c.id)).map(c => c.id)
      : activeList.model === 'company'
      ? companies.filter(c => !selectedIds.includes(c.id)).map(c => c.id)
      : []

    apiClient.put(buildApiUrl(`/lists/${activeList.id}`), { ids: ids })
      .then(() => {
        if(activeList.model === 'contact') {
          setContacts((prevContacts) => 
            prevContacts.filter((c) => !selectedIds.includes(c.id))
          )
          setCompanies([])
          showNotification(t('contact_has_been_removed_from_list'), 'success')
        } else if(activeList.model === 'company') {
          setContacts([])
          setCompanies((prevCompanies) => 
            prevCompanies.filter((c) => !selectedIds.includes(c.id))
          )
          showNotification(t('company_has_been_removed_from_list'), 'success')
        }
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  const handleFetchContactsOrCompanies = () => {
    setIsLoading(true)
    const formattedModel =
      activeList.model === 'contact'
      ? 'contacts'
      : activeList.model === 'company'
      ? 'companies'
      : ''
    apiClient.get(buildApiUrl(`/${formattedModel}`, { lists_ids: [activeList.id] }))
      .then((response) => {
        if(activeList.model === 'contact') {
           setContacts(response.data)
           setCompanies([])
        } else if(activeList.model === 'company') {
          setContacts([])
          setCompanies(response.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleDestroyContact = (contact) => {
    apiClient
      .delete(buildApiUrl(`/contacts/${contact.id}`))
      .then(() => {
        setContacts((prevContacts) => 
          prevContacts.filter((c) => c.id !== contact.id)
        )
        showNotification(t('contact_deleted') , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  const handleDestroyCompany = (company) => {
    apiClient
      .delete(buildApiUrl(`/companies/${company.id}`))
      .then(() => {
        setCompanies((prevCompanies) => 
          prevCompanies.filter((c) => c.id !== company.id)
        )
        showNotification(t('company_deleted') , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  useEffect(() => {
    activeList && handleFetchContactsOrCompanies()
  }, [activeList])

  useEffect(() => {
    handleFetchLists()
  }, [])

  const models = [{name: t('contacts_lists'), id: 0}, {name: t('companies_lists'), id: 1}]

  return (
    <Layout>
      <Topbar
        cta={t('create_new_list')}
        ctaAction={() => handleChangeIsCreatingNewList(true)}
        icon={<FontAwesomeIcon icon={faRectangleList} />}
        title={t('lists')}
      />
      <Tabs
        activeTab={listModel}
        handleChangeTab={(model) => handleChangeListModel(model)}
        tabs={models}
      />
      {isCreatingNewList ?
        <ListCreator
          handleClose={() => handleChangeIsCreatingNewList(false)}
          handleCreateList={(listParams) => handleCreateList(listParams)}
        />
        :
        <div className='lists'>
          <ListSelector
            activeList={activeList}
            handleChangeActiveList={(list) => handleChangeActiveList(list)}
            handleDetroyList={(list) => handleDetroyList(list)}
            listModel={listModel}
            lists={lists}
          />
          {activeList && activeList.model === 'contact' &&
            <PeopleTable
              contacts = {contacts}
              handleRemoveFromList={(ids) => handleRemoveFromList(ids)}
              handleDestroyContact={(contact) => handleDestroyContact(contact)}
              isLoading={isLoading}
              type='list'
            />
          }
          {activeList && activeList.model === 'company' &&
            <CompaniesTable
              companies = {companies}
              handleRemoveFromList={handleRemoveFromList}
              handleDestroyCompany={(company) => handleDestroyCompany(company)}
              isLoading={isLoading}
              type='list'
            />
          }
        </div>
      }
    </Layout>
  )
}

export default Lists
