import React from 'react'
import { useTranslation } from 'react-i18next'

const Tabs = ({ activeTab, handleChangeTab, tabs }) => {
  const { t } = useTranslation('global')

  return (
    <div className='tabs'>
      {tabs.map((tab) => {
        return (
          <div
            className={activeTab.id === tab.id ? 'tabs__item active' : 'tabs__item'}
            key={tab.value}
            onClick={() => handleChangeTab(tab)}
          >
            {tab.icon && <span className='tabs__item__icon'>{t(tab.icon)}</span>}
            {t(tab.text) || t('new')}
          </div>
        )
      })}
    </div>
  )
}

export default Tabs
