import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsersViewfinder } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import SearchFilters from 'features/SearchFilters'
import SearchPeopleResults from 'features/SearchPeopleResults'
import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const SearchPeople = () => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState({})
  const [contactsResults, setContactsResults] = useState({contacts: [], context: {}})

  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }
  
  const handleSubmitFilters = (page) => {
    setIsLoading(true)
    setCurrentFilters(newFilters)

    const filtersToFetch = Object.fromEntries(
      Object.entries(newFilters).map(([key, value]) => {
        if (Array.isArray(value) && value.every(item => item.value !== undefined)) {
          return [key, value.map(item => item.value)]
        } else if (value && typeof value === 'object' && 'value' in value) {
          return [key, value.value]
        } else {
          return [key, value]
        }
      })
    )
    apiClient
      .post(buildApiUrl('/contacts/search', [], page), filtersToFetch)
      .then((response) => {
        setContactsResults(response.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleImportContacts = (contacts_linkedin_ids) => {
    apiClient.post(buildApiUrl('/contacts'), {'contact_linkedin_ids': contacts_linkedin_ids})
      .then(() => {
        showNotification(t('contacts_added', {count: contacts_linkedin_ids.length}) , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  const hasNewFilters =
    Object.keys(newFilters).length !== Object.keys(currentFilters).length || 
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])

  useEffect(() => {
    hasNewFilters && handleSubmitFilters()
  }, [])

  const contactsTotal = (contactsResults && contactsResults.context && contactsResults.context.total_count)

  return (
    <Layout>
      <Topbar
        description={t('search_people_description')}
        icon={<FontAwesomeIcon icon={faUsersViewfinder} />}
        title={t('search_people')}
        number={contactsTotal}
      />
      <Tabs
        activeTab={{text: t('new_search'), value: 0}}
        tabs={[{text: t('new_search'), value: 0}]}
      />
      <div className='people'>
        <SearchFilters
          handleChangeFilters={handleChangeFilters}
          type='searchPeople'
          newFilters={newFilters}
        />
        <SearchPeopleResults
          contacts={contactsResults.contacts}
          isLoading={isLoading}
          handleImportContacts={handleImportContacts}
          handleCancelFilters={handleCancelFilters}
          handleSubmitFilters={handleSubmitFilters}
          hasNewFilters={hasNewFilters}
          searchContext={contactsResults.context}
        />
      </div>
    </Layout>
  )
}

export default SearchPeople
