import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faIdCard, faFilter, faEarthAmerica, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons'

import MultiSelect from 'components/MultiSelect'
import Search from 'components/Search'
import Select from 'components/Select'
import Toggle from 'components/Toggle'

import {
  COMPANY_SIZES_MIN,
  COMPANY_SIZES_MAX,
  COMPANY_TYPES,
  FOLLOWERS_COUNT_MIN,
  FOLLOWERS_COUNT_MAX,
  LANGUAGES,
  SENIORITIES,
  STATUS,
  TENURE_MIN,
  TENURE_MAX,
  PROGRESS
} from 'constants/filters'

const Filters = ({ handleChangeFilters, newFilters, type }) => {
  const { t } = useTranslation('global')
  const [visibleFilters, setVisibleFilters] = useState([0, 1, 2, 3])

  const handleChangeVisibleFilters = (key) => {
    setVisibleFilters((prevFilters) => {
      if (prevFilters.includes(key)) {
        return prevFilters.filter((filterKey) => filterKey !== key)
      } else {
        return [...prevFilters, key]
      }
    })
  }

  return (
    <div className='filters'>
      {type === 'people' && (
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(0)}>
            <div>
              <FontAwesomeIcon icon={faFilter} />
              <span>{t('general_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(0) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(0) ? 'filters__list' : 'filters__list closed'}>
            <div className='filters__list__item'>
              <span>{t('status')}</span>
              <Select
                choices={STATUS}
                handleChangeFilters={handleChangeFilters}
                item='status'
                newValue={newFilters.status}
                placeholder={t('ex_connected')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('progress')}</span>
              <div className='filters__list__item__with-two-selects'>
                <Select
                  choices={PROGRESS}
                  handleChangeFilters={handleChangeFilters}
                  item='progress_min'
                  newValue={newFilters.progress_min}
                  placeholder={t("min")}
                />
                <Select
                  choices={PROGRESS}
                  handleChangeFilters={handleChangeFilters}
                  item='progress_max'
                  newValue={newFilters.progress_max}
                  placeholder={t("max")}
                />
              </div>
            </div>
            <div className='filters__list__item'>
              <span>{t('has_validated_email')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='is_validated_email' newValue={newFilters.is_validated_email} />
            </div>
            <div className='filters__list__item'>
              <span>{t('has_validated_phone')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='is_validated_phone' newValue={newFilters.is_validated_phone} />
            </div>
            <div className='filters__list__item'>
              <span>{t('has_linkedin_profile')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='has_linkedin_profile' newValue={newFilters.has_linkedin_profile} />
            </div>
          </div>
        </div>
      )}

      {['searchPeople'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(1)}>
            <div>
              <FontAwesomeIcon icon={faIdCard} />
              <span>{t('contacts_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(1) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(1) ? 'filters__list' : 'filters__list closed'}>
            <div className='filters__list__item'>
              <span>{t('full_name')}</span>
              <Search
                handleChangeFilters={handleChangeFilters}
                item='full_name'
                newValue={newFilters.full_name}
                placeholder={t('john_doe')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('job_title')}</span>
              <MultiSelect
                handleChangeFilters={handleChangeFilters}
                item='job_title'
                newValue={newFilters.job_title}
                placeholder={t('ex_accountant')}
              />
            </div>
            {type === 'searchPeople' &&
              <div className='filters__list__item'>
                <span>{t('department')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='department'
                  newValue={newFilters.department}
                  placeholder={t('ex_finance')}
                />
              </div>
            }
            {type === 'searchPeople' &&
              <div className='filters__list__item'>
                <span>{t('seniority')}</span>
                <MultiSelect
                  choices={SENIORITIES}
                  handleChangeFilters={handleChangeFilters}
                  item='seniority'
                  newValue={newFilters.seniority}
                  placeholder={t('ex_director')}
                />
              </div>
            }
            {['searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('time_in_company')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={TENURE_MIN}
                    handleChangeFilters={handleChangeFilters}
                    item='tenure_at_company_min'
                    newValue={newFilters.tenure_at_company_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={TENURE_MAX}
                    handleChangeFilters={handleChangeFilters}
                    item='tenure_at_company_max'
                    newValue={newFilters.tenure_at_company_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
            {['searchPeople', 'people'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('time_in_position')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={TENURE_MIN}
                    handleChangeFilters={handleChangeFilters}
                    item='tenure_at_position_min'
                    newValue={newFilters.tenure_at_position_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={TENURE_MAX}
                    handleChangeFilters={handleChangeFilters}
                    item='tenure_at_position_max'
                    newValue={newFilters.tenure_at_position_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
            <div className='filters__list__item'>
              <span>{t('location')}</span>
              <MultiSelect
                item='location'
                handleChangeFilters={handleChangeFilters}
                newValue={newFilters.location}
                placeholder={t('ex_north_america')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('language')}</span>
              <MultiSelect
                choices={LANGUAGES.sort((a, b) => isNaN(Number(a.text)) && t(a.text).toLowerCase().localeCompare(t(b.text).toLowerCase()))}
                item='language'
                handleChangeFilters={handleChangeFilters}
                newValue={newFilters.language}
                placeholder={t('ex_english')}
              />
            </div>
            {['searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('school')}</span>
                <MultiSelect
                  item='school'
                  handleChangeFilters={handleChangeFilters}
                  newValue={newFilters.school}
                  placeholder={t('ex_harvard')}
                />
              </div>
            }
          </div>
        </div>
      }
      
      {['searchCompanies', 'searchPeople'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(2)}>
            <div>
              <FontAwesomeIcon icon={faEarthAmerica} />
              <span>{t('company_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(2) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(2) ? 'filters__list' : 'filters__list closed'}>
            {['searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='company'
                  newValue={newFilters.company}
                  placeholder={t('microsoft')}
                />
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company')}</span>
                <Search
                  handleChangeFilters={handleChangeFilters}
                  item='company_name'
                  newValue={newFilters.company_name}
                  placeholder={t('microsoft')}
                />
              </div>
            }
            {['searchCompanies', 'searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_size')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={COMPANY_SIZES_MIN}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_min'
                    newValue={newFilters.company_size_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={COMPANY_SIZES_MAX}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_max'
                    newValue={newFilters.company_size_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_size_growth')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={[...Array(101).keys()].map(i => ({value: i, text: i + '%'}))}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_growth_min'
                    newValue={newFilters.company_size_growth_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={[...Array(101).keys()].map(i => ({value: i, text: i + '%'}))}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_growth_max'
                    newValue={newFilters.company_size_growth_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
            {['searchCompanies', 'searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_industry')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='company_industry'
                  newValue={newFilters.company_industry}
                  placeholder={t('ex_retail')}
                />
              </div>
            }
            {['searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_type')}</span>
                <MultiSelect
                  choices={COMPANY_TYPES}
                  handleChangeFilters={handleChangeFilters}
                  item='company_type'
                  newValue={newFilters.company_type}
                  placeholder={t('ex_privately_held')}
                />
              </div>
            }
            {['searchCompanies', 'searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('headquarter')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='company_location'
                  newValue={newFilters.company_location}
                  placeholder={t('ex_north_america')}
                />
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('department_headcount')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='department_headcount_name'
                  newValue={newFilters.department_headcount_name}
                  placeholder={t('ex_finance')}
                />
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('department_headcount_range')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={COMPANY_SIZES_MIN}
                    isDisabled={!newFilters.department_headcount_name}
                    handleChangeFilters={handleChangeFilters}
                    item='department_headcount_min'
                    newValue={newFilters.department_headcount_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={COMPANY_SIZES_MAX}
                    isDisabled={!newFilters.department_headcount_name}
                    handleChangeFilters={handleChangeFilters}
                    item='department_headcount_max'
                    newValue={newFilters.department_headcount_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('department_headcount_growth')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={[...Array(101).keys()].map(i => ({value: i, text: i + '%'}))}
                    isDisabled={!newFilters.department_headcount_name}
                    handleChangeFilters={handleChangeFilters}
                    item='department_headcount_growth_min'
                    newValue={newFilters.department_headcount_growth_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={[...Array(101).keys()].map(i => ({value: i, text: i + '%'}))}
                    isDisabled={!newFilters.department_headcount_name}
                    handleChangeFilters={handleChangeFilters}
                    item='department_headcount_growth_max'
                    newValue={newFilters.department_headcount_growth_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      }
      {['searchPeople', 'searchCompanies'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(3)}>
            <div>
              <FontAwesomeIcon icon={faTowerBroadcast} />
              <span>{t('signals_and_intents')}</span>
            </div>
            <div
              className={visibleFilters.includes(3) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(3) ? 'filters__list' : 'filters__list closed'}>
            {['searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('has_recently_changed_job')}</span>
                <Toggle handleChangeFilters={handleChangeFilters} item='has_recently_changed_job' newValue={newFilters.has_recently_changed_job} />
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className= 'filters__list__item'>
                <span>{t('company_is_recruiting')}</span>
                <Toggle handleChangeFilters={handleChangeFilters} item='company_is_recruiting' newValue={newFilters.company_is_recruiting} />
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('technologies_used')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='technologies'
                  newValue={newFilters.technologies}
                  placeholder={t('ex_google_ads')}
                />
              </div>
            }
            {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('annual_revenue')}</span>
                <Search
                  handleChangeFilters={handleChangeFilters}
                  item='annual_revenue'
                  newValue={newFilters.annual_revenue}
                  placeholder={t('ex_10,000,000')}
                  type='number'
                />
              </div>
            }
           {['searchCompanies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('followers_count')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={FOLLOWERS_COUNT_MIN}
                    handleChangeFilters={handleChangeFilters}
                    item='followers_count_min'
                    newValue={newFilters.followers_count_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={FOLLOWERS_COUNT_MAX}
                    handleChangeFilters={handleChangeFilters}
                    item='followers_count_max'
                    newValue={newFilters.followers_count_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Filters
