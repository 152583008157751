import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faWandMagicSparkles, faTrash, faPaperPlane ,faPlus, faBan, faUser, faPhoneSlash, faPhone, faSpinner, faThumbsUp, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { capitalizeFirstLetter } from 'services/functions'

import Loader from 'components/Loader'

const PeopleTable = ({
  contacts,
  handleCancelFilters,
  handleEnrichPhone,
  handleRemoveFromList,
  handleSubmitFilters,
  hasNewFilters,
  isLoading,
  type
}) => {
  const { t } = useTranslation('global')
  const [selectedContacts, setSelectedContacts] = useState([])

  const handleToggleContact = (contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        return prevSelectedContacts.filter((id) => id !== contactId)
      } else {
        return [...prevSelectedContacts, contactId]
      }
    })
  }

  const handleToggleAllContacts = () => {
    setSelectedContacts(() => {
      if (allSelected) {
        return []
      } else {
        return contacts.map((contact) => contact.id);
      }
    })
  }

  const handleAddToList = () => {
    // apiClient.post(buildApiUrl(`/lists/${selectedListId}/add_contacts`), {ids: selectedContacts})
    // .then(() => {
    //   showNotification(t('contact_added_to_list') , 'success')
    // })
    // .catch(() => {
    //   showNotification(t('error_occured') , 'error')
    // })
    console.log('Add Modal')
  }

  useEffect(() => {
    setSelectedContacts([])
  }, [contacts])

  const allSelected = contacts && contacts.length > 0 ? contacts.every((contact) => selectedContacts.includes(contact.id)) : []

  if (isLoading) { return <div className='results'><Loader /></div> }

  return (
    <div className={hasNewFilters ? 'results blurred' : 'results'}>
      {hasNewFilters && (
        <div className='results__filters-layer'>
          <p className='results__filters-layer__text'>{t('apply_filters_message')}</p>
          <div className='results__filters-layer__ctas'>
            <div className='results__filters-layer__ctas__cancel' onClick={handleCancelFilters}>{t('cancel')}</div>
            <div className='main-btn' onClick={handleSubmitFilters}>{t('apply_filters')}</div>
          </div>
        </div>
      )}
      {selectedContacts && selectedContacts.length > 0 && !hasNewFilters &&
        <>
          <div className='results__multiple_actions'>
            <p className='results__multiple_actions_text'>{t('contacts_selected', {count: selectedContacts.length})}</p>
            <div className='results__multiple_actions_item'>
              <div className={`alt-btn`} onClick={handleAddToList}>
                <FontAwesomeIcon icon={faPlus} />
                <span className='results__multiple_actions__cta_text'>{t('add_to_list')}</span>
              </div>
            </div>
          </div>
        </>
      }
      {type === 'list' && selectedContacts && selectedContacts.length > 0 &&
        <div className='results__multiple_actions'>
          <div className='results__multiple_actions__cta_container'>
            <div className='remove-btn' onClick={() => handleRemoveFromList(selectedContacts)}>
              <FontAwesomeIcon icon={faTrash} />
              <span className='results__multiple_actions__cta_text'>{t('remove_from_list')}</span>
            </div>
          </div>
        </div>
      }
      {!hasNewFilters && contacts.length > 0 && 
        <table className='results__table'>
          <thead>
            <tr>
              <th><input type='checkbox' checked={allSelected} onChange={handleToggleAllContacts} /></th>
              <th>{t('name')}</th>
              <th>{t('linkedin')}</th>
              <th>{t('job_title')}</th>
              <th>{t('company')}</th>
              <th>{t('status')}</th>
              <th>{t('email')}</th>
              <th>{t('phone')}</th>
              <th>{t('progress')}</th>
              <th>{t('company_size')}</th>
              <th>{t('company_industry')}</th>
              <th>{t('headquarter')}</th>
              <th>{t('location')}</th>
              <th>{t('language')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => {
              return (
                <tr key={contact.id}>
                  <td>
                    <input
                      type='checkbox'
                      id={contact.id}
                      checked={selectedContacts && selectedContacts.includes(contact.id)}
                      onChange={() => handleToggleContact(contact.id)}
                    />
                  </td>
                  <td className='results__table__with-picture'>
                    {contact.picture_url ?
                      <img src={contact.picture_url} alt={contact.first_name + ' ' +contact.last_name} className='results__table__picture' />
                      :
                      <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faUser} />
                    }
                    {contact.linkedin_id ?
                      <a className='results__table__link' href={'https://www.linkedin.com/in/' + contact.linkedin_id} target='_blank' rel="noreferrer">
                        {contact.first_name} {contact.last_name}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                      :
                      <div className='results__table__link'>
                        {contact.first_name} {contact.last_name}
                      </div>
                    }
                  </td>
                  <td>
                    {contact.linkedin_id &&
                      <a className='results__table__link' href={'https://www.linkedin.com/in/' + contact.linkedin_id} target='_blank' rel="noreferrer">
                        <FontAwesomeIcon className='results__table__linkedin__icon' icon={faLinkedin} />
                      </a>
                    }
                  </td>
                  <td>{contact.job_title}</td>
                  <td>
                    <div className='results__table__with-picture'>
                      {contact.company_picture_url ?
                        <img src={contact.company_picture_url} alt={contact.company_name} className='results__table__picture' />
                        :
                        <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faBuilding} />
                      }
                      {contact.company_linkedin_id ?
                        <a className='results__table__link' href={'https://www.linkedin.com/company/' + contact.company_linkedin_id} target='_blank' rel="noreferrer">
                          {contact.company_name ? contact.company_name : t('unknown')}
                          <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                        </a>
                        :
                        <div className='results__table__link'>
                          {contact.company_name ? contact.company_name : t('unknown')}
                        </div>
                      }
                    </div>
                  </td>
                  <td><div className={`results__table__status ${contact.status}`}>
                    {contact.status === 'enriching' && <FontAwesomeIcon className='results__table__status__icon' icon={faSpinner} />}
                    {contact.status === 'enriched' && <FontAwesomeIcon className='results__table__status__icon' icon={faThumbsUp} />}
                    <span>{capitalizeFirstLetter(contact.status)}</span>
                  </div></td>
                  <td>
                    {contact.email && contact.email !== 'not_found' &&
                      <div>{contact.email}</div>
                    }
                    {contact.email === 'not_found' &&
                      <div className='results__table__not_found'>
                        <FontAwesomeIcon icon={faBan} />
                        <span>{t('not_found')}</span>
                      </div>
                    }
                  </td>
                  <td>
                    {contact.phone && contact.phone !== 'not_found' &&
                      <div>{contact.phone}</div>
                    }
                    {contact.phone === 'not_found' &&
                      <div className='results__table__not_found'>
                        <FontAwesomeIcon icon={faPhoneSlash} />
                        <span>{t('not_found')}</span>
                      </div>
                    }
                    {!contact.phone && contact.status !== 'enriching' &&
                      <div className='results__table__enrich__cta' onClick={() => handleEnrichPhone(contact)}>
                        <FontAwesomeIcon icon={faPhone} />
                        <span>{t('find_phone')}</span>
                      </div>
                    }
                  </td>
                  <td>
                    <div className={`results__table__progress progress_${'weak'}`}>
                      <div className='results__table__progress__bar' style={{ width: `${20*0.8}px` }}></div>
                      <span className='results__table__progress__number'>{'20%'}</span>
                    </div>
                  </td>
                  <td>{contact.company_size}</td>
                  <td>{t(contact.company_industry)}</td>
                  <td>{t(contact.company_headquarter)}</td>
                  <td>{t(contact.location)}</td>
                  <td>{t(contact.language)}</td>
                  <td>
                    <div className='results__actions'>
                      <div className='results__actions__item'>
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </div>
                      <div className='results__actions__item ai'>
                        <FontAwesomeIcon icon={faWandMagicSparkles} />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      }
    </div>
  )
}

export default PeopleTable
