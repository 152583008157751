import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { PARAMETER_MAPPING } from 'constants/filters'
 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons'

const MultiSelect = ({choices, handleChangeFilters, item, isDisabled, newValues, placeholder}) => {
  const { t } = useTranslation('global')
  const [isOpen, setIsOpen] = useState(false)
  const [values, setValues] = useState(newValues || [])
  const [search, setSearch] = useState('')
  const [filteredChoices, setFilteredChoices] = useState(choices || [])
  const [isMounted, setIsMounted] = useState(false)
  const selectRef = useRef(null)
  const inputRef = useRef(null)

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    setValues(newValues || '')
  }, [newValues])

  useEffect(() => {
    const controller = new AbortController()
  
    const fetchFilteredChoices = async () => {
      try {
        setFilteredChoices([])
  
        if (['company_industry', 'department', 'department_headcount_name', 'location', 'company_location', 'company', 'job_title', 'school', 'technologies'].includes(item)) {
          const response = await apiClient.get(
            buildApiUrl(`/parameters`, { type: PARAMETER_MAPPING[item], keywords: search.toLowerCase() }),
            { signal: controller.signal }
          )
          setFilteredChoices(response.data)
        } else {
          const filtered = !search
            ? choices
            : choices
                ? choices.filter((choice) =>
                    t(choice.text).toLowerCase().includes(search.toLowerCase()) ||
                    choice.text.toLowerCase().includes(search.toLowerCase())
                  )
                : []
          setFilteredChoices(filtered)
        }
      } catch (error) {
        return
      }
    }
  
    if (isMounted) {
      fetchFilteredChoices()
    } else {
      setIsMounted(true)
    }
  
    return () => {
      controller.abort()
    }
  }, [search, choices])
  
  const fetchParameters = () => {
    if(['company_industry', 'department', 'department_headcount_name', 'location', 'company_location', 'company', 'school', 'technologies'].includes(item)) {
      apiClient.get(buildApiUrl(`/parameters`, {type: PARAMETER_MAPPING[item]}))
        .then((response) => {
          setFilteredChoices(response.data)
        })
    }
  }

  const toggleList = () => {
    search === '' && filteredChoices && filteredChoices.length === 0 && fetchParameters()
    !isDisabled && setIsOpen(!isOpen)
  }

  const handleChangeValues = (choice) => {
    const newValues = values.includes(choice) 
      ? values.filter(item => item !== choice) 
      : [...values, choice]
    setValues(newValues)
    handleChangeFilters(item, newValues)
  }

  const handleRemoveValue = () => {
    setValues('')
    handleChangeFilters(item, null)
  }

  const formattedValues = values ? values.map(value => value.text).map(text => t(text)).join(', ') : ''

  const handleChangeSearch = (search) => {
    setSearch(search.toLowerCase())
  }

  return (
    <div className={isDisabled ? 'multiselect disabled' : 'multiselect'} ref={selectRef}>
      {values && values.length > 0 && !isOpen ?
        <div className='select__value'>
          <span onClick={toggleList}>{formattedValues}</span>
          {!isDisabled && <FontAwesomeIcon className='select__value__trash' icon={faTrash} onClick={handleRemoveValue} />}
        </div>
        :
        !isOpen &&
          <div className='select__button' onClick={toggleList}>
            <span className='select__button__placeholder'>{placeholder ? t(placeholder) : t('select')}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
      }
      {isOpen &&
      <>
        <input
          className='select__button'
          onChange={(e) => handleChangeSearch(e.target.value)}
          ref={inputRef}
          value={search}
        />
        <div className='select__button__close__btn' onClick={toggleList}><FontAwesomeIcon icon={faChevronUp} /></div>
        <ul className='select__list'>
          {filteredChoices && filteredChoices.length > 0 && filteredChoices
            .map(choice => {
              return (
                <li
                  key={item + choice.value}
                  className={`${values.includes(choice) ? 'selected' : ''}`}
                  onClick={() => handleChangeValues(choice)}
                >
                  {t(choice.text)}
                </li>
              )
          })}
        </ul>
      </>
      } 
    </div>
  )
}

export default MultiSelect
