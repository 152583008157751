export const STATUS = [
  {value: "enriching", text: "enriching"},
  {value: "enriched", text: "enriched"},
  {value: "contacted", text: "contacted"},
  {value: "connected", text: "connected"},
  {value: "meeting_booked", text: "meeting_booked"},
  {value: "meeting_done", text: "meeting_done"},
  {value: "opportunity", text: "opportunity"},
  {value: "won", text: "won"},
  {value: "lost", text: "lost"},
]

export const LANGUAGES = [
  { value: "ar", text: "ar" },
  { value: "bg", text: "bg" },
  { value: "cs", text: "cs" },
  { value: "cy", text: "cy" },
  { value: "da", text: "da" },
  { value: "de", text: "de" },
  { value: "el", text: "el" },
  { value: "en", text: "en" },
  { value: "es", text: "es" },
  { value: "et", text: "et" },
  { value: "eu", text: "eu" },
  { value: "fa", text: "fa" },
  { value: "fi", text: "fi" },
  { value: "fr", text: "fr" },
  { value: "ga", text: "ga" },
  { value: "gl", text: "gl" },
  { value: "he", text: "he" },
  { value: "hi", text: "hi" },
  { value: "hr", text: "hr" },
  { value: "hu", text: "hu" },
  { value: "id", text: "id" },
  { value: "is", text: "is" },
  { value: "it", text: "it" },
  { value: "ja", text: "ja" },
  { value: "ko", text: "ko" },
  { value: "lt", text: "lt" },
  { value: "lv", text: "lv" },
  { value: "ms", text: "ms" },
  { value: "mt", text: "mt" },
  { value: "nl", text: "nl" },
  { value: "no", text: "no" },
  { value: "pl", text: "pl" },
  { value: "pt", text: "pt" },
  { value: "ro", text: "ro" },
  { value: "ru", text: "ru" },
  { value: "sk", text: "sk" },
  { value: "sl", text: "sl" },
  { value: "sr", text: "sr" },
  { value: "sv", text: "sv" },
  { value: "th", text: "th" },
  { value: "tr", text: "tr" },
  { value: "uk", text: "uk" },
  { value: "vi", text: "vi" },
  { value: "zh", text: "zh" }
]

export const COMPANY_SIZES_MIN = [
  {value: "1", text: "1"},
  {value: "11", text: "11"},
  {value: "51", text: "51"},
  {value: "201", text: "201"},
  {value: "501", text: "501"},
  {value: "1001", text: "1001"},
  {value: "5001", text: "5001"},
  {value: "10001", text: "10001"},
]

export const COMPANY_SIZES_MAX = [
  {value: "10", text: "10"},
  {value: "50", text: "50"},
  {value: "200", text: "200"},
  {value: "500", text: "500"},
  {value: "1000", text: "1000"},
  {value: "5000", text: "5000"},
  {value: "10000", text: "10000"},
]

export const TENURE_MIN = [
  {value: "0", text: "0"},
  {value: "1", text: "1"},
  {value: "3", text: "3"},
  {value: "6", text: "6"},
  {value: "10", text: "10"}
]

export const TENURE_MAX = [
  {value: "1", text: "1"},
  {value: "2", text: "2"},
  {value: "5", text: "5"},
  {value: "10", text: "10"}
]

export const ICONS = [
  { value: "🚀", text: "🚀" },
  { value: "📞", text: "📞" },
  { value: "✉️", text: "✉️" },
  { value: "💡", text: "💡" },
  { value: "📈", text: "📈" },
  { value: "🌟", text: "🌟" },
  { value: "🤝", text: "🤝" },
  { value: "🎯", text: "🎯" },
  { value: "📆", text: "📆" },
  { value: "⚡", text: "⚡" },
  { value: "💼", text: "💼" },
  { value: "🔥", text: "🔥" },
  { value: "🌐", text: "🌐" },
  { value: "🔑", text: "🔑" },
  { value: "🧩", text: "🧩" },
  { value: "📢", text: "📢" },
  { value: "🏆", text: "🏆" },
  { value: "⏳", text: "⏳" },
  { value: "👥", text: "👥" },
  { value: "💬", text: "💬" }
]

export const SENIORITIES = [
  {value: "owner_partner", text: "Owner / Partner"},
  {value: "cxo", text: "CXO"},
  {value: "vice_president", text: "Vice President"},
  {value: "director", text: "Director"},
  {value: "experienced_manager", text: "Experienced Manager"},
  {value: "entry_level_manager", text: "Entry Level Manager"},
  {value: "strategic", text: "Strategic"},
  {value: "senior", text: "Senior"},
  {value: "entry_level", text: "Entry Level"},
  {value: "in_training", text: "In Training"},
]

export const COMPANY_TYPES = [
  {value: 'public_company', text: 'Public Company'},
  {value: 'privately_held', text: 'Privately Held'},
  {value: 'non_profit', text: 'Non Profit'},
  {value: 'educational_institution', text: 'Educational Institution'},
  {value: 'partnership', text: 'Partnership'},
  {value: 'self_employed', text: 'Self Employed'},
  {value: 'self_owned', text: 'Self Owned'},
  {value: 'government_agency', text: 'Government Agency'},
]

export const PARAMETER_MAPPING = {
  company_industry: 'INDUSTRY',
  department: 'DEPARTMENT',
  department_headcount_name: 'DEPARTMENT',
  location: 'LOCATION',
  company_location: 'LOCATION',
  company: 'COMPANY',
  job_title: 'JOB_TITLE',
  school: 'SCHOOL',
  technologies: 'TECHNOLOGIES'
}

export const PROGRESS = [
  { value: "0", text: "0" },
  { value: "10", text: "10" },
  { value: "20", text: "20" },
  { value: "30", text: "30" },
  { value: "40", text: "40" },
  { value: "50", text: "50" },
  { value: "60", text: "60" },
  { value: "70", text: "70" },
  { value: "80", text: "80" },
  { value: "90", text: "90" },
  { value: "100", text: "100" },
]

export const FOLLOWERS_COUNT_MIN = [
  {value: "1", text: "1"},
  {value: "51", text: "51"},
  {value: "101", text: "101"},
  {value: "1001", text: "1001"},
  {value: "5001", text: "5001"}
]


export const FOLLOWERS_COUNT_MAX = [
  {value: "50", text: "50"},
  {value: "100", text: "100"},
  {value: "1000", text: "1000"},
  {value: "5000", text: "5000"}
]

export const COUNTRIES = [
  { value: "AD", text: "AD" },
  { value: "AE", text: "AE" },
  { value: "AF", text: "AF" },
  { value: "AG", text: "AG" },
  { value: "AI", text: "AI" },
  { value: "AL", text: "AL" },
  { value: "AM", text: "AM" },
  { value: "AO", text: "AO" },
  { value: "AR", text: "AR" },
  { value: "AS", text: "AS" },
  { value: "AT", text: "AT" },
  { value: "AU", text: "AU" },
  { value: "AW", text: "AW" },
  { value: "AZ", text: "AZ" },
  { value: "BA", text: "BA" },
  { value: "BB", text: "BB" },
  { value: "BD", text: "BD" },
  { value: "BE", text: "BE" },
  { value: "BF", text: "BF" },
  { value: "BG", text: "BG" },
  { value: "BH", text: "BH" },
  { value: "BI", text: "BI" },
  { value: "BJ", text: "BJ" },
  { value: "BM", text: "BM" },
  { value: "BN", text: "BN" },
  { value: "BO", text: "BO" },
  { value: "BR", text: "BR" },
  { value: "BS", text: "BS" },
  { value: "BT", text: "BT" },
  { value: "BW", text: "BW" },
  { value: "BY", text: "BY" },
  { value: "BZ", text: "BZ" },
  { value: "CA", text: "CA" },
  { value: "CD", text: "CD" },
  { value: "CF", text: "CF" },
  { value: "CG", text: "CG" },
  { value: "CH", text: "CH" },
  { value: "CI", text: "CI" },
  { value: "CL", text: "CL" },
  { value: "CM", text: "CM" },
  { value: "CN", text: "CN" },
  { value: "CO", text: "CO" },
  { value: "CR", text: "CR" },
  { value: "CU", text: "CU" },
  { value: "CV", text: "CV" },
  { value: "CY", text: "CY" },
  { value: "CZ", text: "CZ" },
  { value: "DE", text: "DE" },
  { value: "DJ", text: "DJ" },
  { value: "DK", text: "DK" },
  { value: "DM", text: "DM" },
  { value: "DO", text: "DO" },
  { value: "DZ", text: "DZ" },
  { value: "EC", text: "EC" },
  { value: "EE", text: "EE" },
  { value: "EG", text: "EG" },
  { value: "ER", text: "ER" },
  { value: "ES", text: "ES" },
  { value: "ET", text: "ET" },
  { value: "FI", text: "FI" },
  { value: "FJ", text: "FJ" },
  { value: "FM", text: "FM" },
  { value: "FR", text: "FR" },
  { value: "GA", text: "GA" },
  { value: "GB", text: "GB" },
  { value: "GD", text: "GD" },
  { value: "GE", text: "GE" },
  { value: "GH", text: "GH" },
  { value: "GI", text: "GI" },
  { value: "GL", text: "GL" },
  { value: "GM", text: "GM" },
  { value: "GN", text: "GN" },
  { value: "GQ", text: "GQ" },
  { value: "GR", text: "GR" },
  { value: "GT", text: "GT" },
  { value: "GW", text: "GW" },
  { value: "GY", text: "GY" },
  { value: "HK", text: "HK" },
  { value: "HN", text: "HN" },
  { value: "HR", text: "HR" },
  { value: "HT", text: "HT" },
  { value: "HU", text: "HU" },
  { value: "ID", text: "ID" },
  { value: "IE", text: "IE" },
  { value: "IL", text: "IL" },
  { value: "IN", text: "IN" },
  { value: "IQ", text: "IQ" },
  { value: "IR", text: "IR" },
  { value: "IS", text: "IS" },
  { value: "IT", text: "IT" },
  { value: "JM", text: "JM" },
  { value: "JO", text: "JO" },
  { value: "JP", text: "JP" },
  { value: "KE", text: "KE" },
  { value: "KG", text: "KG" },
  { value: "KH", text: "KH" },
  { value: "KI", text: "KI" },
  { value: "KM", text: "KM" },
  { value: "KN", text: "KN" },
  { value: "KR", text: "KR" },
  { value: "KW", text: "KW" },
  { value: "KZ", text: "KZ" },
  { value: "LA", text: "LA" },
  { value: "LB", text: "LB" },
  { value: "LC", text: "LC" },
  { value: "LI", text: "LI" },
  { value: "LK", text: "LK" },
  { value: "LR", text: "LR" },
  { value: "LS", text: "LS" },
  { value: "LT", text: "LT" },
  { value: "LU", text: "LU" },
  { value: "LV", text: "LV" },
  { value: "LY", text: "LY" },
  { value: "MA", text: "MA" },
  { value: "MC", text: "MC" },
  { value: "MD", text: "MD" },
  { value: "ME", text: "ME" },
  { value: "MG", text: "MG" },
  { value: "MH", text: "MH" },
  { value: "MK", text: "MK" },
  { value: "ML", text: "ML" },
  { value: "MM", text: "MM" },
  { value: "MN", text: "MN" },
  { value: "MO", text: "MO" },
  { value: "MP", text: "MP" },
  { value: "MR", text: "MR" },
  { value: "MT", text: "MT" },
  { value: "MU", text: "MU" },
  { value: "MV", text: "MV" },
  { value: "MW", text: "MW" },
  { value: "MX", text: "MX" },
  { value: "MY", text: "MY" },
  { value: "MZ", text: "MZ" },
  { value: "NA", text: "NA" },
  { value: "NE", text: "NE" },
  { value: "NG", text: "NG" },
  { value: "NI", text: "NI" },
  { value: "NL", text: "NL" },
  { value: "NO", text: "NO" },
  { value: "NP", text: "NP" },
  { value: "NR", text: "NR" },
  { value: "NU", text: "NU" },
  { value: "NZ", text: "NZ" },
  { value: "OM", text: "OM" },
  { value: "PA", text: "PA" },
  { value: "PE", text: "PE" },
  { value: "PF", text: "PF" },
  { value: "PG", text: "PG" },
  { value: "PH", text: "PH" },
  { value: "PK", text: "PK" },
  { value: "PL", text: "PL" },
  { value: "PT", text: "PT" },
  { value: "PW", text: "PW" },
  { value: "PY", text: "PY" },
  { value: "QA", text: "QA" },
  { value: "RO", text: "RO" },
  { value: "RS", text: "RS" },
  { value: "RU", text: "RU" },
  { value: "RW", text: "RW" },
  { value: "SA", text: "SA" },
  { value: "SB", text: "SB" },
  { value: "SC", text: "SC" },
  { value: "SD", text: "SD" },
  { value: "SE", text: "SE" },
  { value: "SG", text: "SG" },
  { value: "SI", text: "SI" },
  { value: "SK", text: "SK" },
  { value: "SL", text: "SL" },
  { value: "SM", text: "SM" },
  { value: "SN", text: "SN" },
  { value: "SO", text: "SO" },
  { value: "SR", text: "SR" },
  { value: "SS", text: "SS" },
  { value: "ST", text: "ST" },
  { value: "SV", text: "SV" },
  { value: "SY", text: "SY" },
  { value: "SZ", text: "SZ" },
  { value: "TC", text: "TC" },
  { value: "TD", text: "TD" },
  { value: "TG", text: "TG" },
  { value: "TH", text: "TH" },
  { value: "TJ", text: "TJ" },
  { value: "TK", text: "TK" },
  { value: "TL", text: "TL" },
  { value: "TM", text: "TM" },
  { value: "TN", text: "TN" },
  { value: "TO", text: "TO" },
  { value: "TR", text: "TR" },
  { value: "TT", text: "TT" },
  { value: "TV", text: "TV" },
  { value: "TW", text: "TW" },
  { value: "TZ", text: "TZ" },
  { value: "UA", text: "UA" },
  { value: "UG", text: "UG" },
  { value: "US", text: "US" },
  { value: "UY", text: "UY" },
  { value: "UZ", text: "UZ" },
  { value: "VA", text: "VA" },
  { value: "VC", text: "VC" },
  { value: "VE", text: "VE" },
  { value: "VG", text: "VG" },
  { value: "VI", text: "VI" },
  { value: "VN", text: "VN" },
  { value: "VU", text: "VU" },
  { value: "WF", text: "WF" },
  { value: "WS", text: "WS" },
  { value: "YE", text: "YE" },
  { value: "YT", text: "YT" },
  { value: "ZA", text: "ZA" },
  { value: "ZM", text: "ZM" },
  { value: "ZW", text: "ZW" }
]
