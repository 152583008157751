import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faIdCard,
  faFilter,
  faEarthAmerica,
  faTowerBroadcast
} from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import MultiSelect from 'components/MultiSelect'
import Search from 'components/Search'
import Select from 'components/Select'
import Toggle from 'components/Toggle'

import {
  COMPANY_SIZES_MIN,
  COMPANY_SIZES_MAX,
  COMPANY_TYPES,
  COUNTRIES,
  FOLLOWERS_COUNT_MIN,
  FOLLOWERS_COUNT_MAX,
  LANGUAGES,
  STATUS,
  TENURE_MIN,
  TENURE_MAX,
  PROGRESS
} from 'constants/filters'

const Filters = ({ companyLists, contactLists, handleChangeFilters, newFilters, type }) => {
  const { t } = useTranslation('global')
  const [visibleFilters, setVisibleFilters] = useState([0, 1, 2, 3])
  const [companies, setCompanies] = useState([])

  const handleChangeVisibleFilters = (key) => {
    setVisibleFilters((prevFilters) => {
      if (prevFilters.includes(key)) {
        return prevFilters.filter((filterKey) => filterKey !== key)
      } else {
        return [...prevFilters, key]
      }
    })
  }

  const hasValue = (key) => {
    return Boolean(newFilters[key])
  }

  const handleFetchCompanies = () => {
    apiClient.post(buildApiUrl(`/companies/filter`))
      .then((response) => {
        const list = response.data.map(c => {
          return {text: c.name, value: c.id}
        })
        setCompanies(list)
      })
  }

  useEffect(() => {
    if(['people'].includes(type)) {
      handleFetchCompanies()
    }
  }, [type])

  return (
    <div className='filters'>
      {type === 'people' && (
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(0)}>
            <div>
              <FontAwesomeIcon icon={faFilter} />
              <span>{t('general_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(0) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(0) ? 'filters__list' : 'filters__list closed'}>
            <div className='filters__list__item'>
              <span>{t('status')}</span>
              <Select
                choices={STATUS}
                handleChangeFilters={handleChangeFilters}
                item='status'
                newValue={newFilters.status}
                placeholder={t('ex_connected')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('progress')}</span>
              <div className='filters__list__item__with-two-selects'>
                <Select
                  choices={PROGRESS}
                  handleChangeFilters={handleChangeFilters}
                  item='progress_min'
                  newValue={newFilters.progress_min}
                  placeholder={t("min")}
                />
                <Select
                  choices={PROGRESS}
                  handleChangeFilters={handleChangeFilters}
                  item='progress_max'
                  newValue={newFilters.progress_max}
                  placeholder={t("max")}
                />
              </div>
            </div>
            <div className='filters__list__item'>
              <span>{t('Contacts_lists')}</span>
              <Select
                choices={contactLists}
                handleChangeFilters={handleChangeFilters}
                item='contact_list_id'
                newValue={newFilters.contact_list_id}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('has_validated_email')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='is_validated_email' newValue={newFilters.is_validated_email} />
            </div>
            <div className='filters__list__item'>
              <span>{t('has_validated_phone')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='is_validated_phone' newValue={newFilters.is_validated_phone} />
            </div>
            <div className='filters__list__item'>
              <span>{t('has_linkedin_profile')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='has_linkedin_profile' newValue={newFilters.has_linkedin_profile} />
            </div>
          </div>
        </div>
      )}

      {['people'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(1)}>
            <div>
              <FontAwesomeIcon icon={faIdCard} />
              <span>{t('contacts_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(1) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(1) ? 'filters__list' : 'filters__list closed'}>
            <div className='filters__list__item'>
              <span>{t('full_name')}</span>
              <Search
                handleChangeFilters={handleChangeFilters}
                item='full_name'
                newValue={newFilters.full_name}
                placeholder={t('john_doe')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('job_title')}</span>
              <Search
                handleChangeFilters={handleChangeFilters}
                item='job_title'
                newValue={newFilters.job_title}
                placeholder={t('ex_accountant')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('time_in_position')}</span>
              <div className='filters__list__item__with-two-selects'>
                <Select
                  choices={TENURE_MIN}
                  handleChangeFilters={handleChangeFilters}
                  item='tenure_at_position_min'
                  newValue={newFilters.tenure_at_position_min}
                  placeholder={t("min")}
                />
                <Select
                  choices={TENURE_MAX}
                  handleChangeFilters={handleChangeFilters}
                  item='tenure_at_position_max'
                  newValue={newFilters.tenure_at_position_max}
                  placeholder={t("max")}
                />
              </div>
            </div>
            <div className='filters__list__item'>
              <span>{t('language')}</span>
              <MultiSelect
                choices={LANGUAGES.sort((a, b) => isNaN(Number(a.text)) && t(a.text).toLowerCase().localeCompare(t(b.text).toLowerCase()))}
                item='language'
                handleChangeFilters={handleChangeFilters}
                newValue={newFilters.language}
                placeholder={t('ex_english')}
              />
            </div>
          </div>
        </div>
      }
      
      {['companies', 'people'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(2)}>
            <div>
              <FontAwesomeIcon icon={faEarthAmerica} />
              <span>{t('company_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(2) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(2) ? 'filters__list' : 'filters__list closed'}>
            {['people'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company')}</span>
                <MultiSelect
                  choices={companies}
                  handleChangeFilters={handleChangeFilters}
                  item='company_id'
                  newValue={newFilters.company_id}
                />
              </div>
            }
            {['companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company')}</span>
                <Search
                  choices={companies}
                  handleChangeFilters={handleChangeFilters}
                  item='company_name'
                  newValue={newFilters.company_name}
                />
              </div>
            }
            {['people', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('Companies_lists')}</span>
                <Select
                  choices={companyLists}
                  handleChangeFilters={handleChangeFilters}
                  isDisabled={hasValue('companyName') || hasValue('companyId') || hasValue('companySizeMin') || hasValue('companySizeMax')}
                  item='company_list_id'
                  newValue={newFilters.company_list_id}
                />
              </div>
            }
            {['people', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_size')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={COMPANY_SIZES_MIN}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_min'
                    newValue={newFilters.company_size_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={COMPANY_SIZES_MAX}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_max'
                    newValue={newFilters.company_size_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
            {['people', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_industry')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='company_industry'
                  newValue={newFilters.company_industry}
                  placeholder={t('ex_retail')}
                />
              </div>
            }
            {['people', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_type')}</span>
                <MultiSelect
                  choices={COMPANY_TYPES}
                  handleChangeFilters={handleChangeFilters}
                  item='company_type'
                  newValue={newFilters.company_type}
                  placeholder={t('ex_privately_held')}
                />
              </div>
            }
            {['people', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('headquarter')}</span>
                <MultiSelect
                  choices={COUNTRIES.sort((a, b) => isNaN(Number(a.text)) && t(a.text).toLowerCase().localeCompare(t(b.text).toLowerCase()))}
                  handleChangeFilters={handleChangeFilters}
                  item='company_location'
                  newValue={newFilters.company_location}
                  placeholder={t('ex_north_america')}
                />
              </div>
            }
          </div>
        </div>
      }
      {['people', 'companies'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(3)}>
            <div>
              <FontAwesomeIcon icon={faTowerBroadcast} />
              <span>{t('signals_and_intents')}</span>
            </div>
            <div
              className={visibleFilters.includes(3) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(3) ? 'filters__list' : 'filters__list closed'}>
            {['people'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('has_recently_changed_job')}</span>
                <Toggle handleChangeFilters={handleChangeFilters} item='has_recently_changed_job' newValue={newFilters.has_recently_changed_job} />
              </div>
            }
            {['people'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('is_linkedin_premium')}</span>
                <Toggle handleChangeFilters={handleChangeFilters} item='is_linkedin_premium' newValue={newFilters.is_linkedin_premium} />
              </div>
            }
            {['companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('followers_count')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={FOLLOWERS_COUNT_MIN}
                    handleChangeFilters={handleChangeFilters}
                    item='companys_followers_min'
                    newValue={newFilters.companys_followers_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={FOLLOWERS_COUNT_MAX}
                    handleChangeFilters={handleChangeFilters}
                    item='companys_followers_max'
                    newValue={newFilters.companys_followers_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Filters
